<template>
	<div>
		<div style="margin-bottom: 16px">
			<a-button type="danger" :disabled="!hasSelected" :loading="loading" @click="del">
				删除
			</a-button>
		</div>
		<a-table :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" rowKey="id"
			:columns="columns" :data-source="data" :pagination="false">
			<img style="width: 78px;height: 78px;" slot="image" slot-scope="text" :src="text"></img>
			<div slot="product_name" slot-scope="data">
				<div class="text_over_2line">{{data}}</div>
			</div>
			<div slot="price" slot-scope="data">
				<span style="color: #CE0000;">￥{{data}}</span>
			</div>
			<div slot="cart_num" slot-scope="data,record">
				<a-input-number size="large" :min="1" :max="record.limit_num" :default-value="data"
					@change="onChange($event,record.id)" />
			</div>
			<template slot="operation" slot-scope="text, record">
				<div class="df fdc">
					<a-popconfirm v-if="data.length" title="你确定要删除吗?" @confirm="() => del(record.id)">
						<a href="javascript:;">删除</a>
					</a-popconfirm>
				</div>
			</template>
		</a-table>
		<div class="row df jcfe aic">
			<div class="df fdc aife">
				<span v-if="hasSelected" style="margin-left: 8px">{{ `选择了 ${selectedRowKeys.length} 件商品` }}</span>
				<span v-if="totalPrice">总价<span style="color: #ff4d4f;">{{totalPrice}}￥</span>(不含运费)</span>
			</div>
			<a-button :disabled="!hasSelected" style="margin-left: 20px;width: 100px;" type="primary" size="large" @click="buy">去结算</a-button>
		</div>
	</div>
</template>
<script>
	const columns = [{
			title: '商品图片',
			dataIndex: 'image',
			key: 'image',
			width: 100,
			scopedSlots: {
				customRender: 'image'
			},
		},
		{
			title: '商品信息',
			dataIndex: 'product_name',
			key: 'product_name',
			width: 220,
			scopedSlots: {
				customRender: 'product_name'
			},
		},
		{
			title: '单价',
			dataIndex: 'price',
			key: 'price',
			scopedSlots: {
				customRender: 'price'
			},
		},
		{
			title: '数量',
			dataIndex: 'cart_num',
			key: 'cart_num',
			scopedSlots: {
				customRender: 'cart_num'
			},
		},
		{
			title: '操作',
			dataIndex: 'operation',
			scopedSlots: {
				customRender: 'operation'
			},
		},
	];

	export default {
		data() {
			return {
				data: [],
				columns,
				selectedRowKeys: [], // Check here to configure the default column
				loading: false,
				timer: null,
				totalPrice: null
			};
		},
		computed: {
			hasSelected() {
				return this.selectedRowKeys.length > 0;
			},
		},
		mounted() {
			this.cartList()
		},
		methods: {
			async buy(){
				let {
					orderKey
				} = await this.$api.prevOrder(this.selectedRowKeys.join(','))
				this.$go(`/ConfirmOrder?orderKey=${orderKey}`)
			},
			onChange(num, id) {
				if (this.timer) clearTimeout(this.timer)
				this.timer = setTimeout(async () => {
					await this.$api.updateCart({
						id,
						num
					})
					this.cartList();
				}, 1000)
				this.setCart()
			},
			async cartList() {
				let {
					list
				} = await this.$api.cartList()
				this.data = list
			},
			async del(id) {
				this.loading = true;
				await this.$api.delCart(this.selectedRowKeys.join(',')||id)
				this.loading = false;
				this.$notification.success({
					message: '删除成功'
				});
				this.cartList()
				this.setCart()
			},
			onSelectChange(selectedRowKeys, selectedRows) {
				this.data.forEach((v1, i1) => {
					v1.checked = false
					if (selectedRows.length) {
						selectedRowKeys.forEach(v2 => {
							if (v1.id == v2) v1.checked = true
						})
					} else {
						v1.checked = false
					}

				})
				this.selectedRowKeys = selectedRowKeys;
				this.setCart()
			},
			setCart() {
				this.totalNum = 0
				this.totalPrice = 0
				this.data.forEach(v => {
					if (v.checked) {
						this.totalPrice += v.cart_num * Number(v.price);
						this.totalNum += v.cart_num
					}
				})
				// this.allChecked = this.cartLst.every(v => v.is_select) ? true : false;
			}
		},
	};
</script>
<style lang="scss" scoped>
	@import '@/common/scss/turn.scss';

	.row {
		margin-top: 30px;
	}
</style>
